<template>
  <div class="assets-list list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="6">
          <el-form-item label="关键字" prop="keyword">
            <el-input v-model="formData.keyword" placeholder="请输入资产编号/资产名称/RFID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="资产类型" prop="type">
            <el-select v-model="formData.type" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工段" prop="workStation">
            <el-select v-model="formData.workStation" placeholder="请选择">
              <el-option
                v-for="item in workStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- <el-form-item label="班组" prop="classGroup">
            <el-select v-model="formData.classGroup" placeholder="请选择">
              <el-option
                v-for="item in classGroupList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="工位" prop="empStation">
            <el-input v-model="formData.empStation" placeholder="请输入工位号"></el-input>
            <!--<el-select v-model="formData.empStation" placeholder="请选择">
              <el-option
                v-for="item in empStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="资产状态" prop="status">
            <el-select v-model="formData.status" placeholder="请选择">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="点检状态" prop="tpmStatus">
            <el-select v-model="formData.tpmStatus" placeholder="请选择">
              <el-option
                v-for="item in tpmStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--<el-col :span="6">
          <el-form-item label="位置状态" prop="currentEmpStationStatus">
            <el-select v-model="formData.currentEmpStationStatus" placeholder="请选择">
              <el-option
                v-for="item in tpmStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>-->
        <el-col :span="6" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button @click="handleMultiQRCode"><img src="@/assets/imgs/qr-code.png"/>批量下载二维码</el-button>
          <el-button class="active" @click="handleAdd" v-if="userRole != 'STATION_ADMIN'"><img
            src="@/assets/imgs/add.png"/>新增资产
          </el-button>
          <el-button @click="handleExport" v-if="userRole != 'STATION_ADMIN'"><img src="@/assets/imgs/export.png"/>导出
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        ref="multipleTable"
        row-key="id"
        @select="handleSelect"
        @select-all="handleSelectAll">
        <el-table-column type="selection" width="50" :reserve-selection="true"></el-table-column>
        <el-table-column prop="code" label="资产编号"></el-table-column>
        <el-table-column prop="name" label="资产名称"></el-table-column>
        <el-table-column prop="usedTimes" label="已使用次数" width="100"></el-table-column>
        <el-table-column prop="typeName" label="资产类型" width="90"></el-table-column>
        <!--        <el-table-column prop="settingValue" label="设定值" width="120"></el-table-column>-->
        <el-table-column prop="workStationName" label="工段" width="70"></el-table-column>
        <el-table-column prop="classGroupName" label="班组" width="50"></el-table-column>
        <el-table-column prop="empStationName" label="工位" width="60"></el-table-column>
        <!--  <el-table-column label="当前所在工位" width="120">
            <template #default="{row}">
              <span :class="{'warning': row.currentEmpStation != row.empStationName}">{{row.currentEmpStation}}</span>
            </template>
          </el-table-column>-->
        <el-table-column label="资产状态" width="70">
          <template #default="{row, column}">
            <span :class="{'warning': row.status != 'OK'}">{{formatterStatus(row, column, row.status)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="点检状态" width="70">
          <template #default="{row, column}">
            <span :class="{'warning': row.tpmStatus != 'OK'}">{{formatterTpmStatus(row, column, row.tpmStatus)}}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="lastTpmTime" label="最近点检时间" width="140"></el-table-column>-->
        <el-table-column label="操作" width="120" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleEdit(row)" v-if="userRole != 'STATION_ADMIN'">修改</el-button>
            <el-dropdown class="el-button">
              <span>更多</span>
              <template #dropdown>
                <el-dropdown-menu class="table-operate-col-menu">
                  <el-dropdown-item @click="handleQRCode(row)">二维码</el-dropdown-item>
                  <el-dropdown-item @click="handleTpmItems(row)">配置点检项</el-dropdown-item>
                  <el-dropdown-item @click="handlePts(row)">配置产能</el-dropdown-item>
                  <el-dropdown-item @click="handleResetAssets(row)">清除次数</el-dropdown-item>
                  <el-dropdown-item @click="handleCopy(row)" v-if="userRole != 'STATION_ADMIN'">复制</el-dropdown-item>
                  <!--                  <el-dropdown-item @click="handleOperate(row)" v-if="userRole != 'STATION_ADMIN'">操作记录</el-dropdown-item>-->
                  <el-dropdown-item @click="handleDelete(row)" v-if="userRole != 'STATION_ADMIN'">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <el-button @click="handleCopy(row)">复制</el-button>
            <el-button @click="handleOperate(row)">操作记录</el-button>
            <el-button @click="handleQRCode(row)">二维码</el-button>
            <el-button @click="handleDelete(row)" class="del">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 修改弹框 -->
    <el-dialog v-model="editDialogVisible" width="1300px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}资产</h1>
        <p>{{curEditLabel.en}} asset</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="el-row add-form" :rules="editFormRules">
        <el-col :span="8">
          <el-form-item label="资产编号" prop="code">
            <el-input v-model="editFormData.code"
                      :disabled="['Edit', 'Copy'].indexOf(curEditLabel.en) > -1 && editCodeDisabled" placeholder="请输入">
              <template v-if="['Edit', 'Copy'].indexOf(curEditLabel.en) > -1" #append>
                <el-button v-if="editCodeDisabled" @click="editCodeDisabled = false">修改</el-button>
                <el-button v-if="!editCodeDisabled" @click="editCodeDisabled = true">确认</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="RFID" prop="rfid">
            <el-input v-model="editFormData.rfid"
                      :disabled="['Edit', 'Copy'].indexOf(curEditLabel.en) > -1 && editRfidDisabled" placeholder="请输入">
              <template v-if="['Edit', 'Copy'].indexOf(curEditLabel.en) > -1" #append>
                <el-button v-if="editRfidDisabled" @click="editRfidDisabled = false">修改</el-button>
                <el-button v-if="!editRfidDisabled" @click="editRfidDisabled = true">确认</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="资产名称" prop="name">
            <el-input v-model="editFormData.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="资产类型" prop="type">
            <el-select v-model="editFormData.type" placeholder="请选择" @change="onAssetTypeChange">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产状态" prop="status">
            <el-select v-model="editFormData.status" placeholder="请选择">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="A/B套" prop="abFlag" v-if="editFormData.isShowAbFlag">
            <el-select v-model="editFormData.abFlag" clearable placeholder="请选择">
              <el-option
                v-for="item in abList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工段" prop="workStation">
            <el-select v-model="editFormData.workStation" placeholder="请选择">
              <el-option
                v-for="item in workStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工位" prop="empStation">
            <el-select v-model="editFormData.empStation" filterable placeholder="请选择">
              <el-option
                v-for="item in empStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班组" prop="classGroup">
            <el-select v-model="editFormData.classGroup" placeholder="请选择">
              <el-option
                v-for="item in classGroupList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="点检状态" prop="tpmStatus">
            <el-select v-model="editFormData.tpmStatus" placeholder="请选择">
              <el-option
                v-for="item in tpmStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设定值" prop="settingValue">
            <el-input v-model="editFormData.settingValue" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="型号" prop="assetModel">
            <el-input v-model="editFormData.assetModel" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="转速(r/min)" prop="rotationRate">
            <el-input v-model="editFormData.rotationRate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="精度" prop="accuracy">
            <el-input v-model="editFormData.accuracy" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="有效期" prop="expireDate">
            <el-date-picker
              v-model="editFormData.expireDate"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="重量" prop="weight">
            <el-input v-model="editFormData.weight" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="功率" prop="power">
            <el-input v-model="editFormData.power" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="体积" prop="volume">
            <el-input v-model="editFormData.volume" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <!-- 新增、修改时的确认按钮 -->
        <el-button v-if="['Add', 'Edit'].indexOf(curEditLabel.en) > -1" class="active"
                   @click="confirmEdit('editFormRef')">确 定
        </el-button>
        <!-- 复制时的确认按钮 -->
        <el-button v-if="['Copy'].indexOf(curEditLabel.en) > -1" class="active" @click="confirmCopy('editFormRef')">确
          定
        </el-button>
      </template>
    </el-dialog>


    <!-- 配置点检项弹框 -->
    <el-dialog v-model="checkItemDialogVisible" width="1200px" :show-close="false">
      <template #title>
        <h1>配置点检项 </h1>
        <p>Configuration check items</p>
      </template>
      <el-table :data="currentAssetTpmItems">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="itemValue" label="点检项" show-overflow-tooltip></el-table-column>
        <el-table-column prop="itemPoints" label="检查点"></el-table-column>
        <el-table-column prop="tpmMethod" label="点检方法"></el-table-column>
        <el-table-column prop="tpmStatus" label="状态"></el-table-column>
        <el-table-column prop="tpmRate" label="点检频次"></el-table-column>
        <el-table-column prop="formType" width="80" label="表单类型" :formatter="formatterFormType"></el-table-column>
        <el-table-column prop="inputStandardValue" label="标准值"></el-table-column>
        <el-table-column prop="sort" width="60" label="排序"></el-table-column>
        <el-table-column label="操作" width="100" class-name="operate-col">
          <template #default="{row}">
            <el-button v-if="row.formType === 'INPUT'" @click="editInputValue(row)">设置标准值</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="checkItemDialogVisible = false">关 闭</el-button>
      </template>

      <!--&lt;!&ndash; 编辑点检项弹框 &ndash;&gt;
      <el-dialog v-model="editCheckItemDialogVisible" width="600px" append-to-body>
        <template #title>
          <h1>{{curEditCheckItemLabel.zh}}</h1>
          <p>{{curEditCheckItemLabel.en}}</p>
        </template>
        <el-form :model="checkItemEditFormData" ref="checkItemEditFormRef" class="edit-form" :rules="checkItemEditFormRules">
          <el-form-item label="输入名称" prop="itemName">
            <el-input v-model="checkItemEditFormData.itemName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="检查点" prop="itemPoints">
            <el-input v-model="checkItemEditFormData.itemPoints" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="点检方法" prop="tpmMethod">
            <el-input v-model="checkItemEditFormData.tpmMethod" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="tpmStatus">
            <el-input v-model="checkItemEditFormData.tpmStatus" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="点检频次" prop="tpmRate">
            <el-input v-model="checkItemEditFormData.tpmRate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="checkItemEditFormData.sort" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="表单类型" prop="formType">
            <el-select v-model="checkItemEditFormData.formType" placeholder="请选择" @change="() => checkItemEditFormData.inputStandardValue =''">
              <el-option v-for="item in formTypeList" :value="item.value" :label="item.label" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标准值" prop="inputStandardValue" v-if="checkItemEditFormData.formType == 'INPUT'">
            <el-input v-model="checkItemEditFormData.inputStandardValue" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="cancelEditCheckItem('checkItemEditFormRef')">取 消</el-button>
          <el-button class="active" @click="confirmEditCheckItem('checkItemEditFormRef')">保 存</el-button>
        </template>
      </el-dialog>-->
    </el-dialog>


    <!-- 操作记录 -->
    <el-dialog v-model="operateDialogVisible" width="860px" @close="operateDialogVisible = false">
      <template #title>
        <h1>操作记录</h1>
        <p>Operation record</p>
      </template>
      <div class="operate-content">
        <ul class="summary" v-if="curOperateRow">
          <li><label>资产名称</label><span>{{curOperateRow.name}}</span></li>
          <li><label>资产编号</label><span>{{curOperateRow.code}}</span></li>
        </ul>
        <el-table :data="operateTableData">
          <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
          <el-table-column prop="content" label="操作内容"></el-table-column>
          <el-table-column prop="operator" label="操作人" width="80"></el-table-column>
          <el-table-column prop="createTime" label="操作时间" width="160"></el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            @current-change="handleOperateCurrentPage"
            :current-page="operatePagination.currentPage"
            :page-size="operatePagination.pageSize"
            layout="prev, pager, next"
            :total="operatePagination.total">
          </el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button @click="operateDialogVisible = false" class="active">关 闭</el-button>
      </template>
    </el-dialog>

    <!-- 修改弹框 -->
    <el-dialog v-model="assetPtsDialogVisible" width="960px" :show-close="false" @close="hideAssetPtsDialogVisible()">
      <template #title>
        <h1>关键工具产能</h1>
        <p>Asset Pts</p>
        <el-button class="add-capacity" icon="el-icon-plus" @click="addAssetPtsTableRow">新增产能</el-button>
      </template>
      <el-table :data="assetPtsTableData">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column label="车型">
          <template #default="{row, $index}">
            <el-input v-if="curPtsTableRowIdx === $index" v-model="row.carType"></el-input>
            <span v-else>{{row.carType}}</span>
          </template>
        </el-table-column>
        <el-table-column label="拧紧次数">
          <template #default="{row, $index}">
            <el-input v-if="curPtsTableRowIdx === $index" v-model="row.needTimes"></el-input>
            <span v-else>{{row.needTimes}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="{row, $index}">
            <el-button v-if="curPtsTableRowIdx !== $index" @click="curPtsTableRowIdx = $index">修改</el-button>
            <el-button v-if="curPtsTableRowIdx === $index" @click="confirmPtsTableRow(row)">确定</el-button>
            <el-button @click="delPtsTableRow($index)" class="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="hideAssetPtsDialogVisible()">取 消</el-button>
        <el-button class="active" @click="confirmPtsTable()">确 定</el-button>
      </template>
    </el-dialog>


    <!-- 二维码 -->
    <div v-show="false" ref="qrcodeContainer"></div>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import {assets, common} from '@/api'

  const _ = require('lodash')
  import {parseJwt} from '@/utils/common'

  export default {
    name: 'AssetsList',
    data() {
      return {
        formData: { // 查询表单
          keyword: '',
          type: '',
          workStation: '',
          status: '',
          tpmStatus: '',
          currentEmpStationStatus: '',
          // classGroup: '',
          empStation: ''
        },
        selectedData: [], // 选中的行
        tableData: [], // 表格数据
        pagination: { // 分页信息
          currentPage: 1,
          pageSize: 10,
          total: 0
        },
        typeList: [], // 资产类型列表
        workStationList: [], // 工段列表
        statusList: [], // 资产状态列表
        tpmStatusList: [{
          label: '正常',
          value: 'OK'
        },
          {
            label: '异常',
            value: 'ABNORMAL'
          }], // 点检状态列表
        empStationList: [], // 工位列表
        classGroupList: [], // 班组列表

        curEditLabel: {
          zh: '',
          en: '',
        },
        initEditFormData: {
          code: '',
          rfid: '',
          name: '',
          type: '',
          workStation: '',
          empStation: '',
          classGroup: '',
          status: 'OK',
          tpmStatus: 'OK',
          settingValue: '',
          abFlag: '',
          assetModel: '',
          rotationRate: '',
          accuracy: '',
          expireDate: '',
          weight: '',
          power: '',
          volume: '',
          isShowAbFlag: false
        },
        editDialogVisible: false, // 修改弹框visible
        editFormData: {...this.initEditFormData}, // 修改表单
        editFormRules: { // 修改表单的校验
          code: {required: true, message: '请输入', trigger: 'blur'},
          // rfid: { required: true, message: '请输入', trigger: 'blur' },
          name: {required: true, message: '请输入', trigger: 'blur'},
          type: {required: true, message: '请输入', trigger: 'blur'},
          workStation: {required: true, message: '请输入', trigger: 'blur'},
          empStation: {required: true, message: '请输入', trigger: 'blur'},
          status: {required: true, message: '请输入', trigger: 'blur'},
          // tpmStatus: { required: true, message: '请输入', trigger: 'blur' },
          classGroup: {required: true, message: '请选择', trigger: 'change'},
          // settingValue: { required: true, message: '请输入', trigger: 'blur' },
        },
        editCodeDisabled: true, // 资产编号输入框的不可用性
        editRfidDisabled: true, // RFID输入框的不可用性
        abList: [
          {
            label: 'A',
            value: 'A',
          },
          {
            label: 'B',
            value: 'B',
          }
        ],

        operateDialogVisible: false, // 操作记录弹框visible
        curOperateRow: null, // 当前操作记录
        operateTableData: [], // 操作记录列表
        operatePagination: { // 操作记录分页信息
          currentPage: 1,
          pageSize: 5,
          total: 0
        },
        checkItemDialogVisible: false,
        currentAssetTpmItems: [],
        formTypeList: [
          {
            label: '选择',
            value: 'SELECT'
          },
          {
            label: '输入',
            value: 'INPUT'
          }
        ],
        assetPtsDialogVisible: false,
        assetPtsTableData: [],
        curPtsTableRowIdx: -1
      }
    },
    computed: {
      userRole() {
        if (localStorage.getItem('wlzcToken')) {
          return parseJwt(localStorage.getItem('wlzcToken').split(' ')[1]).role
        }
        return ''
      },
    },
    created() {
      this.getDict()
      this.loadTableData()
    },
    methods: {
      async handlePts({id}) {
        this.curPtsAssetId = id;
        this.assetPtsDialogVisible = true;
        const {code, msg, data = []} = await assets.getAssetPtsItems({assetId: id});
        if (code === 0) {
          this.assetPtsTableData = data;
          this.curPtsTableRowIdx = -1;
        } else {
          this.$message.error(msg)
        }
      },
      hideAssetPtsDialogVisible: function () {
        this.assetPtsDialogVisible = false;
        this.curPtsTableRowIdx = -1;
        this.assetPtsTableData = [];
        this.curPtsAssetId = ''
      },
      confirmPtsTableRow: function (row) {
        if (row.carType.trim() && row.needTimes.toString().trim()) {
          const valid = /^(?:[1-9]\d*|0)$/.test(row.needTimes)
          if (row.needTimes === 0 || !valid) {
            this.$message.warning('拧紧次数需正整数')
            return
          }
          this.curPtsTableRowIdx = -1
        } else {
          this.$message.warning('请完成填写或删除当前行')
        }
      },
      delPtsTableRow: function (idx) {
        this.assetPtsTableData.splice(idx, 1)
        this.curPtsTableRowIdx = -1
      },
      addAssetPtsTableRow: function () {
        if (this.curPtsTableRowIdx === -1) {
          this.assetPtsTableData.push({
            carType: '',
            needTimes: ''
          });
          this.curPtsTableRowIdx = this.assetPtsTableData.length - 1
        } else {
          this.$message.warning('请完成填写或删除当前行')
        }
      },
      confirmPtsTable: async function () {
        if (this.curPtsTableRowIdx == -1) {
          let params = {
            id: this.curPtsAssetId,
            items: this.assetPtsTableData
          };
          const {code, msg} = await assets.saveAssetPtsItems(params)
          if (code === 0) {
            this.$message.success(`保存成功`)
            this.hideAssetPtsDialogVisible()
          } else {
            this.$message.error(msg)
          }
        } else {
          this.$message.warning('请完成填写或删除当前行')
        }
      },

      // 获取字典数据
      async getDict() {
        let types = 'ASSET_TYPE,WORK_STATION,ASSET_STATUS,TPM_STATUS,EMP_STATION,CLASS_GROUP'
        const {code, msg, data = {}} = await common.queryDict({types})
        if (code === 0) {
          this.typeList = data.ASSET_TYPE || []
          this.workStationList = data.WORK_STATION || []
          this.statusList = data.ASSET_STATUS || []
          // this.tpmStatusList = data.TPM_STATUS || []
          this.empStationList = data.EMP_STATION || []
          this.classGroupList = data.CLASS_GROUP || []
        } else {
          this.$message.error(msg)
        }
      },
      // 获取列表数据
      async loadTableData() {
        let params = {
          ...this.formData,
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize
        }
        const {code, msg, data = [], total = 0} = await assets.getAssetsList(params)
        if (code === 0) {
          this.tableData = data
          this.pagination.total = total
        } else {
          this.$message.error(msg)
        }
      },
      // 改变当前页
      handleCurrentPage(currentPageNo) {
        this.pagination.currentPage = currentPageNo
        this.loadTableData()
      },
      // 搜索
      handleSearch() {
        this.pagination.currentPage = 1
        this.selectedData = []
        this.$refs.multipleTable.clearSelection()
        this.loadTableData()
      },
      // 重置
      handleReset(formName) {
        this.$refs[formName].resetFields()
        this.pagination.currentPage = 1
        this.selectedData = []
        this.$refs.multipleTable.clearSelection()
        this.loadTableData()
      },
      // 格式化资产状态
      formatterStatus(row, column, cellValue) {
        let label = cellValue
        for (let item of this.statusList) {
          if (item.value == cellValue) {
            label = item.label
            continue
          }
        }
        return label
      },
      // 格式化点检状态
      formatterTpmStatus(row, column, cellValue) {
        let label = cellValue
        for (let item of this.tpmStatusList) {
          if (item.value == cellValue) {
            label = item.label
            continue
          }
        }
        return label
      },
      // 格式化表单类型
      formatterFormType(row, column, cellValue) {
        let label = cellValue
        for (let item of this.formTypeList) {
          if (item.value == cellValue) {
            label = item.label
            continue
          }
        }
        return label
      },
      onAssetTypeChange(e) {
        this.editFormData.isShowAbFlag = false;
        if (e) {
          for (let item of this.typeList) {
            if (item.value === e) {
              this.editFormData.isShowAbFlag = item.isAb;
              break;
            }
          }
        }
      },
      // 点击新增
      handleAdd() {
        this.editFormData = this.initEditFormData
        this.curEditLabel.zh = '新增'
        this.curEditLabel.en = 'Add'
        this.editDialogVisible = true
      },
      // 点击修改
      handleEdit(row) {
        this.editFormData = {...row}
        this.editFormData.isShowAbFlag = false;
        for (let item of this.typeList) {
          if (item.value === this.editFormData.type) {
            this.editFormData.isShowAbFlag = item.isAb;
            break;
          }
        }
        this.curEditLabel.zh = '修改'
        this.curEditLabel.en = 'Edit'
        this.editDialogVisible = true
      },
      // 确认修改
      confirmEdit(formName) {
        this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const {code, msg} = await assets.saveAssets(this.editFormData)
            if (code === 0) {
              this.editDialogVisible = false
              this.$message.success(`${this.curEditLabel.zh}成功`)
              this.loadTableData()
            } else {
              this.$message.error(msg)
            }
          }
        })
      },
      editInputValue(row) {
        console.log(row);

        this.$prompt('请输入标准值', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async ({value}) => {
          row.inputStandardValue = value;
          const {code, msg} = await assets.saveAssetTpmItem(row)
          if (code === 0) {
            this.loadAssetTpmItems(row.assetId);
          } else {
            this.$message.error(msg)
          }
        });
      },

      // 取消修改
      cancelEdit(formName) {
        this.$refs[formName].resetFields()
        this.editDialogVisible = false

        this.editCodeDisabled = true
        this.editRfidDisabled = true
      },
      // 点击复制
      handleCopy(row) {
        let {id, ...others} = row
        this.editFormData = {
          ...others,
          copyId: id
        }
        this.curEditLabel.zh = '复制'
        this.curEditLabel.en = 'Copy'
        this.editDialogVisible = true
      },
      // 确认复制
      confirmCopy(formName) {
        this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const {code, msg} = await assets.copyAssets(this.editFormData)
            if (code === 0) {
              this.editDialogVisible = false
              this.$message.success(`${this.curEditLabel.zh}成功`)
              this.loadTableData()
            } else {
              this.$message.error(msg)
            }
          }
        })
      },
      // 删除
      handleDelete({id}) {
        this.$confirm('此操作将删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {code, msg} = await assets.deleteAssets({id})
          if (code === 0) {
            this.$message.success(`删除成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }).catch(() => {
          console.info('取消删除操作')
        })
      },
      // 复位
      handleResetAssets({id}) {
        this.$confirm('确认执行复位操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {code, msg} = await assets.resetAssets({id})
          if (code === 0) {
            this.$message.success(`操作成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }).catch(() => {
          console.info('取消复位操作')
        })
      },
      // 生成二维码
      setQRCode(code) {
        new QRCode(this.$refs.qrcodeContainer, {
          width: 100,// 二维码的宽
          height: 100,// 二维码的高
          text: code, // 二维码的内容
          colorDark: '#000',// 二维码的颜色
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        })
      },
      // 下载二维码
      // handleQRCode({name, rfid}) {
      //   this.$refs.qrcodeContainer.innerHTML = '' // 清空二维码
      //   this.setQRCode(rfid)

      //   this.$nextTick(() => {
      //     let canvasData = this.$refs.qrcodeContainer.getElementsByTagName('canvas')
      //     let aLink = document.createElement('a')
      //     aLink.download = `${name}-${rfid}.png`
      //     aLink.href = canvasData[0].toDataURL("image/png")
      //     document.body.appendChild(aLink)
      //     aLink.click()
      //     document.body.removeChild(aLink)
      //   })
      // },
      // 下载二维码
      async handleQRCode({id}) {
        await common.downloadImg(`/asset/qrcode/${id}`)
      },
      async handleTpmItems({id}) {
        this.checkItemDialogVisible = true;
        this.loadAssetTpmItems(id)
      },
      async loadAssetTpmItems(id) {
        const {code, msg, data = []} = await assets.getAssetTpmItems({'id': id});
        if (code === 0) {
          let tpmItems = [];
          for (let item in data) {
            tpmItems.push(data[item]['tpmItem'])
          }
          this.currentAssetTpmItems = tpmItems;
        } else {
          this.$message.error(msg)
        }
      },

      // 批量下载二维码
      handleMultiQRCode() {
        if (this.selectedData.length === 0) {
          this.$message.warning('请选择需要下载的数据！')
          return
        }
        const map = new Map()
        let result = this.selectedData.filter(item => !map.has(item.id) && map.set(item.id, 1))
        if (result.length > 10) {
          this.$message.warning('不可超过10条数据')
        } else {
          result.forEach(item => {
            this.handleQRCode(item)
          })
        }
      },
      // 点击“操作列表”
      handleOperate(row) {
        this.curOperateRow = {...row}
        this.operatePagination.currentPage = 1
        this.loadOperateTableData()

        this.operateDialogVisible = true
      },
      // 导出
      async handleExport() {
        await common.downloadFile('/asset/export')
      },
      // 获取操作列表数据
      async loadOperateTableData() {
        let params = {
          id: this.curOperateRow.id,
          pageNum: this.operatePagination.currentPage,
          pageSize: this.operatePagination.pageSize
        }
        const {code, msg, data = [], total = 0} = await assets.getOperateList(params)
        if (code === 0) {
          this.operateTableData = data
          this.operatePagination.total = total
        } else {
          this.$message.error(msg)
        }
      },
      // 改变操作列表当前页
      handleOperateCurrentPage(currentPageNo) {
        this.operatePagination.currentPage = currentPageNo
        this.loadOperateTableData()
      },
      handleSelect(selection, row) {
        // 如果row在selection里面→增，如果row不在selection里面→减
        let selectionIncludeRow = selection.some(item => item.id === row.id)
        if (selectionIncludeRow) {// 增
          let alreadyExist = false
          for (let i = 0; i < this.selectedData.length; i++) {
            if (this.selectedData[i].id == row.id) {
              alreadyExist = true
              return
            }
          }
          if (!alreadyExist) {
            this.selectedData.push(row)
          }
        } else { // 减
          if (selection.length > 0) {
            this.selectedData = this.selectedData.filter(item => item.id !== row.id)
          } else {
            this.selectedData = []
          }
        }
      },
      /**
       * 全选
       */
      handleSelectAll(selection) {
        this.selectedData = _.merge([], selection)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .assets-list {
    .el-form.add-form {
      background: linear-gradient(180deg, #27273A 0%, #191924 100%);
      border-radius: 16px;
      padding-top: 30px;

      &.el-row > .el-col {
        padding-left: 30px;
        padding-right: 30px;
      }

      .el-form-item {
        margin-bottom: 30px;

        ::v-deep .el-form-item__label {
          width: 92px;
          text-align: left;
          color: #B4B4C5;
          font-size: 14px;
        }

        // 下拉框
        .el-select {
          width: 100%;
          line-height: 36px;
        }

        ::v-deep .el-date-editor {
          width: 100%;
        }
      }
    }

    .operate-content {
      .summary {
        list-style: none;
        display: flex;

        & > li {
          & + li {
            margin-left: 50px;
          }

          label {
            color: #B4B4C5;
            font-size: 13px;
          }

          span {
            margin-left: 20px;
            font-size: 14px;
            color: #FFFFFF;
          }
        }
      }

      .el-table {
        margin-top: 12px;
      }
    }

    ::v-deep .el-dialog {
      .add-capacity {
        position: absolute;
        right: 32px;
        top: 32px;
      }
    }
  }
</style>